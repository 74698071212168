export default {
  schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
  headerToolbar: false,
  initialView: 'resourceTimelineDay',
  refetchResourcesOnNavigate: true,

  selectMirror: true,
  dayMaxEvents: true,
  weekends: true,
  locale: 'en',

  // Resources
  resourceAreaColumns: [
    {
      headerContent: '',
      width: '15vw',
    },
  ],

  resourceAreaWidth: '20%',
  resourceOrder: 'order',

  // Visual
  aspectRatio: 2.25,
  themeSystem: 'bootstrap4',
  firstDay: 1,

  slotLabelFormat: {
    hour: 'numeric',
    minute: '2-digit',
    meridiem: false,
    hourCycle: 'h23', // not in the DateTimeFormatOptions interface
  },
  slotEventOverlap: false,
  slotDuration: '00:15',
  scrollTime: '07:00:00',
  nowIndicator: true,

  height: 'parent',
  allDaySlot: false,
  displayEventTime: false,
  eventMinWidth: 0,
  views: {
    timeGridWeek: {
      // name of view
      //titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
      slotLabelFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: false,
        hourCycle: 'h23', // not in the DateTimeFormatOptions interface
      },
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        hour12: false,
        hourCycle: 'h23',
      },
      slotEventOverlap: true,
      displayEventTime: false,
    },
  },
};
