var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-1 flex-row items-baseline justify-end self-stretch pr-3",
    },
    [
      _c("div", { staticClass: "flex flex-1 flex-row space-x-6" }, [
        _c(
          "div",
          {
            staticClass:
              "flex flex-row flex-wrap items-baseline justify-center gap-x-2",
          },
          [
            _c("p", { staticClass: "text-gray-500" }, [_vm._v("Current WT")]),
            _c(
              "span",
              { staticClass: "font-display text-base font-black md:text-xl" },
              [_vm._v(_vm._s(_vm.currentWaitingTimeFirstCustomer))]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "flex flex-row flex-wrap items-baseline justify-center gap-x-2",
          },
          [
            _c("p", { staticClass: "text-gray-500 md:items-center" }, [
              _vm._v("Last hour avg WT"),
            ]),
            _c(
              "span",
              {
                staticClass: "mb:font-display text-base font-black md:text-xl",
              },
              [_vm._v(_vm._s(_vm.avgWaitTimeOverLastHour))]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "flex flex-row flex-wrap items-baseline justify-center gap-x-2",
          },
          [
            _c("p", { staticClass: "text-gray-500" }, [_vm._v("Queue")]),
            _c(
              "span",
              { staticClass: "font-display text-base font-black md:text-xl" },
              [_vm._v(_vm._s(_vm.numberOfAppointmentsInQueue))]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }