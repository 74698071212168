var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-sm inline-block" }, [
    _c(
      "button",
      {
        staticClass:
          "relative text-left flex items-center w-full text-gray-700\n    hover:text-gray-900 rounded py-1 font-semibold focus:outline-none",
        class: _vm.inactive ? "pointer-events-none" : "",
        on: { click: _vm.useQuinyx },
      },
      [
        _vm.loading
          ? _c(
              "span",
              { staticClass: "inline-flex" },
              [
                _c("base-spinner", { attrs: { size: "sm" } }),
                _c("span", { staticClass: "ml-2" }, [_vm._v("syncing..")]),
              ],
              1
            )
          : _c(
              "span",
              { class: _vm.inactive ? " line-through" : "" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "external-link-alt" },
                }),
                _c("span", { staticClass: "ml-2" }, [
                  _vm._v("Sync with Quinyx schedule"),
                ]),
              ],
              1
            ),
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.inactive && !_vm.loading
            ? _c(
                "span",
                {
                  staticClass:
                    "absolute mx-auto text-center ml-2 px-2 py-1 bg-primary\n        text-white rounded-lg border border-white shadow",
                },
                [_vm._v("cooldown")]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }