<template>
  <div class="schedule-modal fixed z-10 bg-white p-8 shadow" :style="style">
    <div v-if="isRescheduleType" class="mb-5 text-right">
      <router-link
        class="text-primary-dark hover:text-primary-darkest hover:underline"
        :to="'/vetpanel/appointment/' + data.event.appointment_id"
      >
        Go to meeting room
        <font-awesome-icon icon="external-link-alt" class="ml-1" />
      </router-link>
    </div>
    <div class="mb-6 flex items-center justify-between">
      <h1 class="flex-grow text-xl font-bold capitalize">
        {{ title }}
      </h1>
      <button
        v-show="isEditType"
        class="text-red"
        @click="$emit('delete-button-click', data.event)"
      >
        <font-awesome-icon :icon="['far', 'trash-alt']" class="mr-1" /> Delete?
      </button>
    </div>
    <div class="mb-6 flex flex-col">
      <div class="mb-1">
        <span class="mr-3 font-bold">
          {{
            dataCache.event
              ? format(new Date(dataCache.event.start), 'yyyy-MM-dd')
              : dataCache.date
          }}
        </span>
        <span v-if="dataCache.event">
          <font-awesome-icon :icon="['far', 'clock']" class="mr-1" />
          {{ format(new Date(dataCache.event.start), 'HH:mm') }} -
          {{ format(new Date(dataCache.event.end), 'HH:mm') }}
        </span>
      </div>
      <span>{{
        dataCache.resource.title || dataCache.resource.display_name
      }}</span>
    </div>
    <div v-if="isRescheduleType || isEditType" class="mb-6 w-full">
      <base-label for="date"> Date </base-label>
      <secondary-input
        id="date"
        v-model="data.date"
        v-validate="'required|date_format:yyyy-MM-dd'"
        placeholder=""
        name="date"
        type="date"
        @change.native="updateSelection(data.date)"
      />
      <p class="text-red text-sm">
        {{ errors.first('date') }}
      </p>
    </div>
    <div v-if="isRescheduleType || isEditType" class="mb-6 w-full">
      <base-label for="veterinarian"> Veterinarian </base-label>
      <v-select
        id="veterinarian"
        v-model="data.resource"
        v-validate="'required'"
        name="veterinarian"
        class="rounded font-medium text-gray-700"
        :label="digitalClinicId ? 'display_name' : 'name'"
        :options="digitalClinicId ? digitalClinicUsers : vets"
        :clearable="false"
        @change="updateSelection"
      />
      <p class="text-red text-sm">
        {{ errors.first('veterinarian') || getErrorResponse('user_id') }}
      </p>
    </div>
    <div v-if="isRescheduleType" class="mb-6 w-full">
      <base-label for="veterinarian"> Time </base-label>
      <v-select
        id="time"
        v-model="selectedSlot"
        v-validate="'required'"
        name="time"
        class="rounded font-medium text-gray-700"
        placeholder="Select a start time"
        :get-option-label="getTimeOptionLabel"
        :options="slots"
        :clearable="false"
        :loading="loadingSlots"
        @change="updateSelectedSlot"
      />
      <p class="text-red text-sm">
        {{ errors.first('time') || getErrorResponse('booking_datetime') }}
      </p>
    </div>
    <div
      v-else-if="isAddType || isEditType"
      class="mb-6 flex w-full justify-between"
    >
      <div class="mr-4 w-full">
        <base-label for="start"> Start </base-label>
        <secondary-input
          id="start"
          ref="start"
          v-model.lazy="data.start"
          v-validate="'required|date_format:HH:mm|before:end'"
          placeholder="10:00"
          name="start"
          type="time"
          @change.native="timeChange('start', data.start)"
        />
        <p class="text-red text-sm">
          {{ errors.first('start') || getErrorResponse('start') }}
        </p>
      </div>
      <div class="w-full">
        <base-label for="end"> End </base-label>
        <secondary-input
          id="end"
          ref="end"
          v-model.lazy="data.end"
          v-validate="'required|date_format:HH:mm|after:start'"
          placeholder="10:15"
          name="end"
          type="time"
          @change.native="timeChange('end', data.end)"
        />
        <p class="text-red text-sm">
          {{ errors.first('end') || getErrorResponse('end') }}
        </p>
      </div>
    </div>
    <p v-if="error && error.message" class="text-red mb-6 leading-normal">
      {{ error.message }}
    </p>
    <p v-else-if="isDeleteType" class="mb-6">
      Do you want to delete this shift?
    </p>
    <div class="text-right">
      <base-button class="mr-4" @click="$emit('close')"> Cancel </base-button>
      <base-button
        color="primary"
        class="w-32"
        :class="isDeleteType ? 'bg-red' : ''"
        :disabled="loading"
        @click="submit"
      >
        <base-spinner v-if="loading" />
        <template v-else>
          {{ submitText }}
        </template>
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ScheduleApi from '@/api/modules/admin/schedule';
import { format, addMinutes } from 'date-fns';

export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: () => ({}),
    },
    coords: {
      type: Object,
      default: () => ({
        top: '',
        bottom: '',
        left: '',
        right: '',
      }),
    },
    data: {
      type: Object,
      default: () => ({
        date: '',
        resource: {},
        start: '',
        end: '',
        time: '',
        event: {},
      }),
    },
  },

  data() {
    return {
      format,
      dataCache: { ...this.data },
      errorMessage: '',
      slots: [],
      selectedSlot: '',
      loadingSlots: false,
    };
  },

  computed: {
    ...mapGetters('admin/digital-clinic', {
      digitalClinicId: 'getDigitalClinicId',
    }),
    ...mapState('admin/digital-clinic', ['digitalClinicUsers']),
    ...mapState('admin/schedule', ['vets', 'serviceId']),
    ...mapState('admin', ['countryId']),
    ...mapGetters('admin', ['countryTimezone']),

    isDeleteType() {
      return this.type === 'delete';
    },
    isEditType() {
      return this.type === 'edit';
    },
    isAddType() {
      return this.type === 'add';
    },
    isRescheduleType() {
      return this.type === 'reschedule';
    },
    slotSize() {
      return this.data && this.data.event && this.data.event.slotSize;
    },
    style() {
      const width = 350;
      let { top, bottom, left, right } = this.coords;

      // Handle outside of viewport
      if (top > window.innerHeight) {
        top = 0;
        bottom = '';
      }

      if (left + width > window.innerWidth) {
        right = 0;
        left = '';

        top += 136;
        bottom = '';
      }

      return {
        width: `${width}px`,
        top: top !== '' ? `${top}px` : '',
        bottom: bottom !== '' ? `${bottom}px` : '',
        left: left !== '' ? `${left}px` : '',
        right: right !== '' ? `${right}px` : '',
      };
    },
    title() {
      return this.type + (this.isRescheduleType ? ' consultation' : ' shift');
    },
    submitText() {
      switch (this.type) {
        case 'edit':
        case 'reschedule':
          return 'Save';
        case 'add':
          return 'Add shift';
        case 'delete':
          return 'Delete';
        default:
          return 'Submit';
      }
    },
  },

  watch: {
    async data() {
      this.$validator.pause();

      this.data.end = this.data.end === '00:00' ? '23:59' : this.data.end;
      this.dataCache = { ...this.data };

      this.$nextTick(() => {
        this.$validator.resume();
      });

      if (this.isRescheduleType) {
        this.fetchAvailableTimeSlots();
      }
    },
  },

  mounted() {
    this.data.end = this.data.end === '00:00' ? '23:59' : this.data.end;
    this.dataCache = { ...this.data };
  },

  methods: {
    timeChange(name, value) {
      // Trigger validation to recheck start/end time
      this.$validator.validate();

      this.updateSelection(value);
    },
    updateSelection(value) {
      if (value) {
        // Workaround to prevent modal from closing
        setTimeout(() => {
          this.$emit('update-selection', this.data);
        }, 200);

        if (this.isRescheduleType) {
          this.fetchAvailableTimeSlots();
        }
      }
    },
    updateSelectedSlot(value) {
      if (value) {
        this.data.date = format(new Date(value), 'yyyy-MM-dd');
        this.data.start = format(new Date(value), 'HH:mm');
        this.data.end = format(
          addMinutes(new Date(value), this.slotSize),
          'HH:mm'
        );

        // Workaround to prevent modal from closing
        setTimeout(() => {
          this.$emit('update-selection', this.data);
        }, 200);
      }
    },
    getTimeOptionLabel(option) {
      return option ? format(new Date(option), 'HH:mm') : 'Select a time';
    },
    async fetchAvailableTimeSlots() {
      this.loadingSlots = true;
      this.slots = [];
      this.selectedSlot = '';

      const { data: booking } = await ScheduleApi.fetchBooking(
        this.data.event.id
      ).catch(e => {
        this.loadingSlots = false;
        throw Error(e);
      });

      const animalTypeId =
        booking &&
        booking.appointment &&
        booking.appointment.animal &&
        booking.appointment.animal.animal_type &&
        booking.appointment.animal.animal_type.id;

      const { data } = await ScheduleApi.fetchAvailableSlots({
        fromDate: this.data.date,
        toDate: this.data.date,
        countryId: this.countryId,
        serviceId: this.serviceId,
        vetId: this.data.resource.id,
        timezone: this.countryTimezone,
        digitalClinicId: this.digitalClinicId,
        animalTypeId: animalTypeId || null,
      }).catch(e => {
        this.loadingSlots = false;
        throw Error(e);
      });

      this.slots = data.map(slot => {
        return slot && slot.dateTime && slot.dateTime.date
          ? format(new Date(slot.dateTime.date), 'yyyy-MM-dd HH:mm')
          : 'n/a';
      });

      // If haven't changed veterinarian or date, add current slot as selected option
      if (
        format(new Date(this.data.date), 'yyyy-MM-dd') ===
          format(new Date(this.data.event.start), 'yyyy-MM-dd') &&
        this.data.event.resourceId === this.data.resource.id
      ) {
        this.selectedSlot = `${this.data.date} ${this.data.start}`;

        if (!this.slots.includes(this.selectedSlot)) {
          this.slots.push(this.selectedSlot);
          this.slots = this.slots.sort();
        }
      }

      this.loadingSlots = false;
    },
    getErrorResponse(field) {
      return typeof this.error === 'object' &&
        this.error[field] &&
        this.error[field].length
        ? this.error[field].join(' ')
        : '';
    },
    async submit() {
      const isValid = await this.$validator.validate();

      if (isValid) {
        this.$emit('submit', this.data);
      }
    },
  },
};
</script>

<style lang="postcss">
.schedule-modal {
  transition: all 0.2s ease;
}
</style>
