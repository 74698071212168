var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex relative items-baseline text-gray-700",
      class: { "text-gray-lightest": _vm.loading },
    },
    [
      _c("spinner-overlay", {
        attrs: { loading: _vm.loading, color: "transparent" },
      }),
      _c("p", {}, [
        _c("span", {}, [_vm._v("Booked:")]),
        _c("span", { staticClass: "font-semibold ml-1 text-lg" }, [
          _vm._v(_vm._s(_vm.bookedSlotsCount)),
        ]),
      ]),
      _c("p", { staticClass: "ml-3" }, [
        _c("span", {}, [_vm._v("Total:")]),
        _c("span", { staticClass: "font-semibold ml-1 text-lg" }, [
          _vm._v(_vm._s(_vm.availableSlotsCount)),
        ]),
      ]),
      _c("p", { staticClass: "ml-3" }, [
        _c(
          "span",
          {
            staticClass: "font-black text-xl",
            class: { "opacity-50": _vm.loading },
          },
          [
            _vm._v(_vm._s(_vm.availabilityPercentage) + " "),
            _c("span", { staticClass: "text-sm" }, [_vm._v("%")]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }