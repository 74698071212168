<template>
  <div
    class="flex flex-1 flex-row items-baseline justify-end self-stretch pr-3"
  >
    <div class="flex flex-1 flex-row space-x-6">
      <div
        class="flex flex-row flex-wrap items-baseline justify-center gap-x-2"
      >
        <p class="text-gray-500">Current WT</p>
        <span class="font-display text-base font-black md:text-xl">{{
          currentWaitingTimeFirstCustomer
        }}</span>
      </div>
      <div
        class="flex flex-row flex-wrap items-baseline justify-center gap-x-2"
      >
        <p class="text-gray-500 md:items-center">Last hour avg WT</p>
        <span class="mb:font-display text-base font-black md:text-xl">{{
          avgWaitTimeOverLastHour
        }}</span>
      </div>
      <div
        class="flex flex-row flex-wrap items-baseline justify-center gap-x-2"
      >
        <p class="text-gray-500">Queue</p>
        <span class="font-display text-base font-black md:text-xl">{{
          numberOfAppointmentsInQueue
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { WaitingRoomApi } from '@/api';

const RELOAD_TIME = 30000;
export default {
  data() {
    return {
      avgWaitTimeOverLastHour: null,
      currentWaitingTimeFirstCustomer: null,
      numberOfAppointmentsInQueue: null,
      reloadInterval: null,
    };
  },
  async beforeMount() {
    await this.fetchQueueWaitingTimes();
  },
  mounted() {
    this.reloadInterval = setInterval(() => {
      this.fetchQueueWaitingTimes();
    }, RELOAD_TIME);
  },
  beforeDestroy() {
    clearInterval(this.reloadInterval);
  },
  methods: {
    async fetchQueueWaitingTimes() {
      const response = await WaitingRoomApi.fetchQueueWaitingTimes();
      this.avgWaitTimeOverLastHour = response.avgWaitTimeOverLastHour;
      this.currentWaitingTimeFirstCustomer =
        response.currentWaitingTimeFirstCustomer;
      this.numberOfAppointmentsInQueue = response.numberOfAppointmentsInQueue;
    },
  },
};
</script>
