var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _vm.slotsMissingVet
        ? _c("div", { staticClass: "text-xs" }, [
            _c(
              "span",
              { staticClass: "text-red-light mr-2 text-base font-bold" },
              [_vm._v(_vm._s(_vm.slotsMissingVet))]
            ),
            _c("span", { staticClass: "uppercase tracking-wide" }, [
              _vm._v("Slot"),
              _vm.slotsMissingVet > 1 ? _c("span", [_vm._v("s")]) : _vm._e(),
              _vm._v(" missing vet"),
            ]),
          ])
        : _vm._e(),
      _c("schedule-filters", {
        staticClass: "mb-4",
        on: {
          submit: _vm.refreshEventSources,
          "refresh-events": _vm.refreshEventSources,
          "select-today": _vm.setToday,
          "next-date": _vm.setNextDate,
          "prev-date": _vm.setPrevDate,
          "selected-date": _vm.setCalendarDates,
        },
      }),
      _c("schedule-options", {
        staticClass: "w-full md:w-auto",
        on: { "refresh-events": _vm.refreshEventSources },
      }),
      _c(
        "div",
        {
          staticClass: "schedule relative flex w-full",
          staticStyle: { height: "88%" },
        },
        [
          _c("spinner-overlay", {
            attrs: { loading: _vm.loading, color: "light", size: "xl" },
          }),
          _c(
            "transition",
            { attrs: { name: "expand" } },
            [
              _vm.showAppointmentInfo
                ? _c("AppointmentInfo", {
                    staticClass:
                      "absolute right-0 z-50 w-2/3 max-w-xs lg:w-1/4",
                    attrs: { "booking-id": _vm.bookingId },
                    on: {
                      "refresh-events": _vm.closeInfoAndRefresh,
                      closeinfo: _vm.closeInfo,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.modal.show
                ? _c("schedule-modal", {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.closeModal,
                        expression: "closeModal",
                      },
                    ],
                    attrs: {
                      type: _vm.modal.type,
                      loading: _vm.modal.loading,
                      error: _vm.modal.error,
                      coords: _vm.modal.coords,
                      data: _vm.modal.data,
                    },
                    on: {
                      "update-selection": _vm.handleUpdateSelection,
                      deleteButtonClick: _vm.showDeleteModal,
                      close: _vm.closeModal,
                      submit: _vm.modal.submit,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("full-calendar", {
            ref: "calendar",
            staticClass: "w-full",
            attrs: { options: _vm.calendarOptions },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c("AvailabilityInformation", {
            staticClass: "w-full md:w-auto",
            attrs: { "booked-slots": _vm.slots },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }