var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c("spinner-overlay", {
        attrs: { color: "transparent", loading: _vm.loading },
      }),
      _vm.nextAvailableSlot
        ? _c(
            "div",
            {
              staticClass: "flex items-center justify-between space-x-2",
              class: { "opacity-25": _vm.loading },
            },
            [
              _c(
                "div",
                { staticClass: "inline-flex items-center space-x-1 text-sm" },
                [
                  _c("fv-icon", {
                    staticClass: "text-gray-600",
                    attrs: { icon: "clock", size: "xs" },
                  }),
                  _c("span", { staticClass: "font-semibold text-gray-700" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatDistance(
                          new Date(_vm.nextAvailableSlot.dateTime),
                          new Date(),
                          { addSuffix: false }
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }