var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "schedule-modal fixed z-10 bg-white p-8 shadow",
      style: _vm.style,
    },
    [
      _vm.isRescheduleType
        ? _c(
            "div",
            { staticClass: "mb-5 text-right" },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "text-primary-dark hover:text-primary-darkest hover:underline",
                  attrs: {
                    to:
                      "/vetpanel/appointment/" + _vm.data.event.appointment_id,
                  },
                },
                [
                  _vm._v(" Go to meeting room "),
                  _c("font-awesome-icon", {
                    staticClass: "ml-1",
                    attrs: { icon: "external-link-alt" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "mb-6 flex items-center justify-between" }, [
        _c("h1", { staticClass: "flex-grow text-xl font-bold capitalize" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isEditType,
                expression: "isEditType",
              },
            ],
            staticClass: "text-red",
            on: {
              click: function ($event) {
                return _vm.$emit("delete-button-click", _vm.data.event)
              },
            },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "mr-1",
              attrs: { icon: ["far", "trash-alt"] },
            }),
            _vm._v(" Delete? "),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "mb-6 flex flex-col" }, [
        _c("div", { staticClass: "mb-1" }, [
          _c("span", { staticClass: "mr-3 font-bold" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.dataCache.event
                    ? _vm.format(
                        new Date(_vm.dataCache.event.start),
                        "yyyy-MM-dd"
                      )
                    : _vm.dataCache.date
                ) +
                " "
            ),
          ]),
          _vm.dataCache.event
            ? _c(
                "span",
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: ["far", "clock"] },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.format(new Date(_vm.dataCache.event.start), "HH:mm")
                      ) +
                      " - " +
                      _vm._s(
                        _vm.format(new Date(_vm.dataCache.event.end), "HH:mm")
                      ) +
                      " "
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("span", [
          _vm._v(
            _vm._s(
              _vm.dataCache.resource.title ||
                _vm.dataCache.resource.display_name
            )
          ),
        ]),
      ]),
      _vm.isRescheduleType || _vm.isEditType
        ? _c(
            "div",
            { staticClass: "mb-6 w-full" },
            [
              _c("base-label", { attrs: { for: "date" } }, [_vm._v(" Date ")]),
              _c("secondary-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|date_format:yyyy-MM-dd",
                    expression: "'required|date_format:yyyy-MM-dd'",
                  },
                ],
                attrs: {
                  id: "date",
                  placeholder: "",
                  name: "date",
                  type: "date",
                },
                nativeOn: {
                  change: function ($event) {
                    return _vm.updateSelection(_vm.data.date)
                  },
                },
                model: {
                  value: _vm.data.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "date", $$v)
                  },
                  expression: "data.date",
                },
              }),
              _c("p", { staticClass: "text-red text-sm" }, [
                _vm._v(" " + _vm._s(_vm.errors.first("date")) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.isRescheduleType || _vm.isEditType
        ? _c(
            "div",
            { staticClass: "mb-6 w-full" },
            [
              _c("base-label", { attrs: { for: "veterinarian" } }, [
                _vm._v(" Veterinarian "),
              ]),
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "rounded font-medium text-gray-700",
                attrs: {
                  id: "veterinarian",
                  name: "veterinarian",
                  label: _vm.digitalClinicId ? "display_name" : "name",
                  options: _vm.digitalClinicId
                    ? _vm.digitalClinicUsers
                    : _vm.vets,
                  clearable: false,
                },
                on: { change: _vm.updateSelection },
                model: {
                  value: _vm.data.resource,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "resource", $$v)
                  },
                  expression: "data.resource",
                },
              }),
              _c("p", { staticClass: "text-red text-sm" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.errors.first("veterinarian") ||
                        _vm.getErrorResponse("user_id")
                    ) +
                    " "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.isRescheduleType
        ? _c(
            "div",
            { staticClass: "mb-6 w-full" },
            [
              _c("base-label", { attrs: { for: "veterinarian" } }, [
                _vm._v(" Time "),
              ]),
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "rounded font-medium text-gray-700",
                attrs: {
                  id: "time",
                  name: "time",
                  placeholder: "Select a start time",
                  "get-option-label": _vm.getTimeOptionLabel,
                  options: _vm.slots,
                  clearable: false,
                  loading: _vm.loadingSlots,
                },
                on: { change: _vm.updateSelectedSlot },
                model: {
                  value: _vm.selectedSlot,
                  callback: function ($$v) {
                    _vm.selectedSlot = $$v
                  },
                  expression: "selectedSlot",
                },
              }),
              _c("p", { staticClass: "text-red text-sm" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.errors.first("time") ||
                        _vm.getErrorResponse("booking_datetime")
                    ) +
                    " "
                ),
              ]),
            ],
            1
          )
        : _vm.isAddType || _vm.isEditType
        ? _c("div", { staticClass: "mb-6 flex w-full justify-between" }, [
            _c(
              "div",
              { staticClass: "mr-4 w-full" },
              [
                _c("base-label", { attrs: { for: "start" } }, [
                  _vm._v(" Start "),
                ]),
                _c("secondary-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|date_format:HH:mm|before:end",
                      expression: "'required|date_format:HH:mm|before:end'",
                    },
                  ],
                  ref: "start",
                  attrs: {
                    id: "start",
                    placeholder: "10:00",
                    name: "start",
                    type: "time",
                  },
                  nativeOn: {
                    change: function ($event) {
                      return _vm.timeChange("start", _vm.data.start)
                    },
                  },
                  model: {
                    value: _vm.data.start,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "start", $$v)
                    },
                    expression: "data.start",
                  },
                }),
                _c("p", { staticClass: "text-red text-sm" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.errors.first("start") ||
                          _vm.getErrorResponse("start")
                      ) +
                      " "
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("base-label", { attrs: { for: "end" } }, [_vm._v(" End ")]),
                _c("secondary-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|date_format:HH:mm|after:start",
                      expression: "'required|date_format:HH:mm|after:start'",
                    },
                  ],
                  ref: "end",
                  attrs: {
                    id: "end",
                    placeholder: "10:15",
                    name: "end",
                    type: "time",
                  },
                  nativeOn: {
                    change: function ($event) {
                      return _vm.timeChange("end", _vm.data.end)
                    },
                  },
                  model: {
                    value: _vm.data.end,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "end", $$v)
                    },
                    expression: "data.end",
                  },
                }),
                _c("p", { staticClass: "text-red text-sm" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.errors.first("end") || _vm.getErrorResponse("end")
                      ) +
                      " "
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.error && _vm.error.message
        ? _c("p", { staticClass: "text-red mb-6 leading-normal" }, [
            _vm._v(" " + _vm._s(_vm.error.message) + " "),
          ])
        : _vm.isDeleteType
        ? _c("p", { staticClass: "mb-6" }, [
            _vm._v(" Do you want to delete this shift? "),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "base-button",
            {
              staticClass: "mr-4",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "base-button",
            {
              staticClass: "w-32",
              class: _vm.isDeleteType ? "bg-red" : "",
              attrs: { color: "primary", disabled: _vm.loading },
              on: { click: _vm.submit },
            },
            [
              _vm.loading
                ? _c("base-spinner")
                : [_vm._v(" " + _vm._s(_vm.submitText) + " ")],
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }