const VETERINARY_ID = 1;
const DOGBUDDY_ID = 2;
const BEHAVIOURAL_ID = 3;
const DENTAL_ID = 4;
const REHAB_ID = 5;
const PRESCRIPTION_ID = 6;
const NUTRITIONAL_ID = 9;
const HEALTH_CONTROL_ID = 11;
const WELCOME_CALL_ID = 19;
const VETERINARY_CHAT_ID = 20;
const VETERINARY_TECHNICIAN_ID = 23;
// export all constants
export {
  VETERINARY_ID,
  DOGBUDDY_ID,
  BEHAVIOURAL_ID,
  DENTAL_ID,
  REHAB_ID,
  PRESCRIPTION_ID,
  NUTRITIONAL_ID,
  HEALTH_CONTROL_ID,
  WELCOME_CALL_ID,
  VETERINARY_CHAT_ID,
  VETERINARY_TECHNICIAN_ID,
};
