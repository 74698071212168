<template>
  <div
    class="flex relative items-baseline text-gray-700"
    :class="{'text-gray-lightest': loading}"
  >
    <spinner-overlay
      :loading="loading"
      color="transparent"
    />
    <p class="">
      <span class="">Booked:</span>
      <span class="font-semibold ml-1 text-lg">{{ bookedSlotsCount }}</span>
    </p>
    <p class="ml-3">
      <span class="">Total:</span> 
      <span class="font-semibold ml-1 text-lg">{{ availableSlotsCount }}</span>
    </p>
    <p class="ml-3">
      <span
        class="font-black text-xl"
        :class="{'opacity-50': loading}"
      >{{ availabilityPercentage }} <span class="text-sm">%</span></span>
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    bookedSlots: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('admin/slots', ['slots', 'loading']),
    bookedSlotsCount() {
      return this.bookedSlots.length;
    },
    availableSlotsCount() {
      return this.slots.length || 0;
    },
    availabilityPercentage() {
      if (this.loading) {
        return '0.00';
      }
      return ((this.bookedSlotsCount / this.availableSlotsCount) * 100).toFixed(
        2
      );
    },
  },
};
</script>
