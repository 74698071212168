import { ref } from '@vue/composition-api';
import { errorHandler } from '@/utils/error-handler';
import axiosInstance from '@/plugins/axios';

const useAvailability = (url: string, method: string, params: {}) => {
  const result = ref({});
  const loadingState = ref(false);

  const fetchData = async () => {
    loadingState.value = true;
    const { data } = await axiosInstance[method](url, {
      ...params,
    }).catch(e => {
      errorHandler(e);
      loadingState.value = false;
    });

    result.value = data;

    loadingState.value = false;
  };

  return {
    url,
    fetchData,
    loadingState,
    result,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useAvailability };
