<template>
  <div class="h-full">
    <div v-if="slotsMissingVet" class="text-xs">
      <span class="text-red-light mr-2 text-base font-bold">{{
        slotsMissingVet
      }}</span>
      <span class="uppercase tracking-wide"
        >Slot<span v-if="slotsMissingVet > 1">s</span> missing vet</span
      >
    </div>
    <schedule-filters
      class="mb-4"
      @submit="refreshEventSources"
      @refresh-events="refreshEventSources"
      @select-today="setToday"
      @next-date="setNextDate"
      @prev-date="setPrevDate"
      @selected-date="setCalendarDates"
    />
    <schedule-options
      class="w-full md:w-auto"
      @refresh-events="refreshEventSources"
    />
    <div class="schedule relative flex w-full" style="height: 88%">
      <spinner-overlay :loading="loading" color="light" size="xl" />
      <transition name="expand">
        <AppointmentInfo
          v-if="showAppointmentInfo"
          class="absolute right-0 z-50 w-2/3 max-w-xs lg:w-1/4"
          :booking-id="bookingId"
          @refresh-events="closeInfoAndRefresh"
          @closeinfo="closeInfo"
        />
      </transition>

      <transition name="fade">
        <schedule-modal
          v-if="modal.show"
          v-click-outside="closeModal"
          :type="modal.type"
          :loading="modal.loading"
          :error="modal.error"
          :coords="modal.coords"
          :data="modal.data"
          @update-selection="handleUpdateSelection"
          @deleteButtonClick="showDeleteModal"
          @close="closeModal"
          @submit="modal.submit"
        />
      </transition>

      <full-calendar ref="calendar" class="w-full" :options="calendarOptions" />
    </div>
    <div class="flex">
      <AvailabilityInformation class="w-full md:w-auto" :booked-slots="slots" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import AvailabilityInformation from '@/components/admin/schedule/AvailabilityInformation';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import ScheduleApi from '@/api/modules/admin/schedule';
import ScheduleModal from '@/components/admin/schedule/ScheduleModal';
import ScheduleFilters from '@/components/admin/schedule/ScheduleFilters';
import ScheduleOptions from '@/components/admin/schedule/ScheduleOptions';
import AppointmentInfo from '@/components/admin/AppointmentInfo';
import {
  mapShiftsToScheduler,
  mapSlotToScheduler,
  mapUserToScheduler,
} from '@/utils/schedule-utils';
import adminScheduleConfig from '@/config/admin-schedule';

export default {
  components: {
    FullCalendar,
    ScheduleModal,
    ScheduleFilters,
    ScheduleOptions,
    AppointmentInfo,
    AvailabilityInformation,
  },
  props: {
    timezone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      calendar: null,
      scheduleInterval: null,
      initialRender: true,
      slots: [],
      shifts: [],
      floatingSlots: [],
      loading: false,
      modal: {},
      showAppointmentInfo: false,
      appointmentId: null,
      bookingId: null,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
          resourceTimelinePlugin,
        ],
        now: () => {
          return this.timezone
            ? formatInTimeZone(new Date(), this.timezone, 'yyyy-MM-dd HH:mm')
            : new Date();
        },
        resources: async (_, successCallback) => {
          this.loading = true;
          const users = await this.fetchScheduledVets();
          const vets = users.map((user, index) =>
            mapUserToScheduler(user, index)
          );
          successCallback(vets);
          this.loading = false;
        },
        eventClick: calEvent => {
          if (calEvent.event._def.extendedProps.type === 'slot')
            this.displayAppointmentInfo(calEvent.event);
        },
        eventSources: [
          {
            /** Background schedule (shifts) */
            events: async (info, successCallback) => {
              this.loading = true;
              const shifts = await this.handleCalendarShifts(
                info.start,
                info.end
              );
              await this.fetchAvailableSlots();
              await this.handleSlotsWithoutResources(this.slots);
              successCallback(shifts);
              this.loading = false;
            },
          },
          {
            /** Foreground schedule (booked slots) */
            events: async (info, successCallback) => {
              this.loading = true;
              const slots = await this.handleCalendarSlots(info);
              successCallback(slots);
              this.loading = false;
            },
          },
        ],
        ...adminScheduleConfig,
      },
    };
  },
  computed: {
    ...mapState('admin', ['countryId']),
    ...mapGetters('admin', ['countryTimezone']),

    ...mapState('admin/schedule', ['vets', 'vetId', 'fromDate', 'serviceId']),

    ...mapGetters('auth', ['isAdmin']),

    isViewingToday() {
      return (
        formatInTimeZone(
          new Date(this.fromDate),
          this.timezone,
          'yyyy-MM-dd'
        ) === formatInTimeZone(new Date(), this.timezone, 'yyyy-MM-dd')
      );
    },

    slotsMissingVet() {
      return this.slots.filter(slot => slot.resourceId === 1).length;
    },
  },

  async mounted() {
    Promise.all([this.fetchServices(), this.fetchVets()]);
    this.refreshEventSources();
  },

  beforeDestroy() {
    if (this.scheduleInterval) {
      clearInterval(this.scheduleInterval);
    }
  },
  methods: {
    ...mapMutations('admin/schedule', ['setDates']),
    ...mapActions('admin/schedule', [
      'fetchShifts',
      'fetchServices',
      'fetchVets',
      'fetchScheduledVets',
      'fetchBookedSlots',
      'postShift',
      'deleteShift',
      'fetchAllAvailableSlotsCount',
    ]),
    ...mapActions('admin/slots', ['fetchAvailableSlots']),

    async handleCalendarShifts(start, end) {
      const fromDate = format(new Date(start), 'yyyy-MM-dd');
      const toDate = format(new Date(end), 'yyyy-MM-dd');

      this.setDates({ fromDate, toDate });

      const data = await this.fetchShifts();
      const shifts = mapShiftsToScheduler(data, !!this.vetId);
      this.shifts = shifts;
      return shifts;
    },
    async handleCalendarSlots() {
      const data = await this.fetchBookedSlots();
      const slots = data.map(slot => {
        return mapSlotToScheduler(slot);
      });

      this.slots = slots;

      // Set class double if two slots with same title (i.e 12:00) and same resourceId (vet user id)
      for (let i = 0; i < this.slots.length - 1; ++i) {
        //eslint-disable-line
        if (
          this.slots[i].title === this.slots[i + 1].title &&
          this.slots[i].resourceId === this.slots[i + 1].resourceId
        ) {
          this.slots[i].className = 'slot double';
        }
      }
      return slots;
    },

    async handleSlotsWithoutResources(slots) {
      const resources = this.shifts.map(shift => shift.resourceId);
      const slotsWithoutResource = slots.filter(
        slot => !resources.includes(slot.resourceId)
      );
      this.floatingSlots = slotsWithoutResource;
      slotsWithoutResource.forEach(slot => {
        const veterinarian = this.vets.find(vet => vet.id === slot.resourceId);
        const resource = veterinarian
          ? mapUserToScheduler(veterinarian)
          : {
              id: slot.resourceId,
              order: 999,
              title: 'MISSING VETERINARIAN',
              name: 'BOOKINGS WITHOUT COVER',
            };
        this.calendar.addResource(resource);
      });
    },

    refreshEventSources() {
      if (!this.calendar) this.calendar = this.$refs.calendar.getApi();
      var listEvent = this.calendar.getEvents();
      listEvent.map(event => {
        event.remove();
      });
      this.resetInterval();
      if (this.vetId) {
        this.calendar.changeView('timeGridWeek');
      }
      if (!this.vetId) {
        if (this.calendar.view.type !== 'resourceTimelineDay') {
          this.calendar.changeView('resourceTimelineDay');
        }
      }
      this.calendar.refetchResources();
      this.calendar.refetchEvents();
    },

    displayAppointmentInfo(event) {
      this.showAppointmentInfo = false;
      this.bookingId = null;
      this.bookingId = +event._def.publicId;
      this.showAppointmentInfo = true;
    },

    showEventModal(calEvent, jsEvent) {
      // Prevent click-outside-to-close
      jsEvent.stopImmediatePropagation();
      jsEvent.stopPropagation();

      this.removeActiveEventClass();
      this.toggleScrollEnabled(false);

      // Add active class for event
      const element = jsEvent.target.closest('.fc-timeline-event');
      element.classList.add('modal-active', 'modal-active-edit');
      element.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });

      const coords = element.getBoundingClientRect();

      this.modal = {
        show: true,
        loading: false,
        coords: {
          top: coords.top - 100,
          left: coords.right + 10,
        },
        data: {
          date: format(new Date(calEvent.start), 'yyyy-MM-dd'),
          start: format(new Date(calEvent.start), 'HH:mm'),
          end: format(new Date(calEvent.end), 'HH:mm'),
          event: calEvent,
          resource: calEvent.vet || {},
        },
      };

      const resource = this.calendar.getResourceById(calEvent.resourceId);
      resource.id = +resource.id;
      resource.name = resource.title;

      this.modal = {
        ...this.modal,
        type: 'reschedule',
        submit: this.rescheduleConsultation,
        data: {
          ...this.modal.data,
          resource,
        },
      };
    },
    setToday() {
      this.calendar.today();
      this.resetInterval();
    },
    setNextDate() {
      this.calendar.next();
      this.resetInterval();
    },
    setPrevDate() {
      this.calendar.prev();
      this.resetInterval();
    },
    setCalendarDates(date) {
      this.calendar.gotoDate(date);
      this.resetInterval();
    },
    handleUpdateSelection({ date, start, end, resource }) {
      this.calendar.gotoDate(date);
      this.resetInterval();
      this.calendar.select(
        new Date(`${date} ${start}`),
        new Date(`${date} ${end}`),
        resource.id
      );
    },
    async rescheduleConsultation({ date, start, resource, event }) {
      this.modal.error = {};
      this.modal.loading = true;

      const success = await ScheduleApi.updateBooking(event.id, {
        booking_datetime: format(
          new Date(`${this.fromDate} ${start}`),
          'yyyy-MM-dd HH:mm:ss'
        ),
        user_id: +resource.id,
        service_id: this.serviceId,
        country_id: this.countryId,
      }).catch(e => {
        this.modal.error = e.response.data;
        this.modal.loading = false;

        return false;
      });

      if (!success) {
        return;
      }

      this.calendar.gotoDate(date);
      this.calendar.refetchEvents();

      this.closeModal();
    },
    closeModal(event) {
      if (
        event &&
        event.target &&
        event.target.firstElementChild &&
        event.target.firstElementChild.classList.contains('fc-event-container')
      ) {
        // Workaround when clicking outside of modal but want to keep open
        return;
      }

      this.removeActiveEventClass();
      this.calendar.unselect();
      this.modal = {};

      this.toggleScrollEnabled(true);
    },
    removeActiveEventClass() {
      document
        .querySelectorAll('.modal-active')
        .forEach(e =>
          e.classList.remove(
            'modal-active',
            'modal-active-edit',
            'modal-active-delete'
          )
        );
    },
    toggleScrollEnabled(enabled) {
      const scroller = document.querySelector(
        '.fc-body .fc-time-area .fc-scroller'
      );
      scroller.classList.toggle('disable-scroll', !enabled);
    },
    closeInfoAndRefresh() {
      this.refreshEventSources();
      // this.closeInfo();
    },
    closeInfo() {
      this.showAppointmentInfo = false;
      this.bookingId = null;
    },
    resetInterval() {
      if (this.isViewingToday) {
        clearInterval(this.scheduleInterval);
        this.scheduleInterval = setInterval(
          this.refreshEventSources,
          1000 * 60
        );
      }
    },
  },
};
</script>

<style lang="postcss">
.schedule .fc-slats tbody {
  @apply bg-white;
}
.schedule .fc-scroller-harness {
  @apply bg-white;
}
.schedule .fc-event-title {
  font-size: 9px !important;
  @apply font-black;
}
.schedule thead,
.schedule .fc-datagrid-cell-frame,
.schedule .fc-timeline-slot-frame {
  @apply font-medium;
  @apply bg-gray-50 !important;
}
.schedule .fc-timeline th {
  @apply font-medium;
}
.schedule .fc-time-area .fc-event-container {
  @apply h-full !important;
  @apply pb-0;
  @apply top-0;
}
.schedule .fc-time-area .fc-scroller.disable-scroll {
  @apply overflow-hidden !important;
}
.schedule .fc-timeline-events {
  height: 100% !important;
  padding-bottom: 0 !important;
}
.schedule .fc-timeline-event-harness {
  height: 100% !important;
  top: 0% !important;
}
.schedule .fc-timegrid-cols .fc-bg-event {
  opacity: 1;
}
.schedule .fc-timeline-event,
.schedule .fc-bg-event {
  @apply p-0;
  @apply h-full;
  @apply border-0;
  @apply mb-0;
  @apply rounded-none;
  @apply top-0 !important;
  @apply opacity-60;
}
.schedule .fc-timeline-event.slot {
  @apply cursor-pointer;
}
.schedule .fc-timeline-event.slot.double {
  @apply cursor-pointer;
  display: flex;
  align-items: flex-end;
}
.schedule .fc-timeline-event.slot.double .fc-content {
  @apply h-1/2;
  @apply w-full;
  background-opacity: 0.9;
  background-color: #3e3ec9 !important;
}
.schedule .fc-time-grid-event.fc-short .fc-time:before,
.schedule .fc-time-grid-event.fc-short .fc-time:after {
  @apply hidden;
}
.schedule .fc-timeline-event {
  @apply font-semibold;
}
.schedule .fc-timeline-event.slot:hover,
.schedule .fc-timeline-event.slot.modal-active {
  @apply opacity-100;
  z-index: 10;
}
.schedule .fc-timeline-event.shift {
  @apply opacity-50;
}
.schedule .fc-timeline-event.shift.modal-active-delete {
  @apply bg-red-500 !important;
}
.schedule .fc-timeline-event.shift {
  @apply cursor-pointer;
}
.schedule .fc-timeline-event.shift:not(.modal-active):hover {
  outline: 1px solid black;
}
.schedule .fc-highlight {
  outline: 2px dashed black;
}
.schedule .fc-event .fc-bg {
  opacity: 0;
}
.schedule .fc-timegrid-now-indicator-container {
  background: white;
}
.schedule .fc-theme-standard .fc-timeGridWeek-view td,
.schedule .fc-theme-standard .fc-timeGridWeek-view th {
  border-right: none;
  border-left: none;
}
.schedule .fc-timeGridWeek-view .fc-bg-event {
  border-radius: 3px;
  border: rgb(58, 135, 173) solid 1px;
}
</style>
