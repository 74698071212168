<template>
  <div
    class="text-sm inline-block"
  >
    <button
      class="relative text-left flex items-center w-full text-gray-700
      hover:text-gray-900 rounded py-1 font-semibold focus:outline-none"
      :class="inactive ? 'pointer-events-none' : ''"
      @click="useQuinyx"
    >
      <span
        v-if="loading"
        class="inline-flex"
      >
        <base-spinner size="sm" />
        <span class="ml-2">syncing..</span>
      </span>
      <span
        v-else
        :class="inactive ? ' line-through' : ''"
      >
        <font-awesome-icon icon="external-link-alt" />
        <span class="ml-2">Sync with Quinyx schedule</span>
      </span>
      <transition name="fade">
        <span
          v-if="inactive && !loading"
          class="absolute mx-auto text-center ml-2 px-2 py-1 bg-primary
          text-white rounded-lg border border-white shadow"
        >cooldown</span>
      </transition>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import QuinyxApi from '@/api/modules/admin/quinyx';

export default {
  data() {
    return {
      openOptions: true,
      inactive: false,
      loading: false,
    };
  },
  computed: {
    ...mapState('admin', ['countryId']),
    ...mapState('admin/schedule', ['fromDate', 'toDate']),
  },
  methods: {
    async useQuinyx() {
      this.inactive = true;
      this.loading = true;
      const { data } = await QuinyxApi.fetchSchedules({
        fromDate: this.fromDate,
        toDate: this.toDate,
        countryId: this.countryId,
      }).catch(e => {
        this.inactive = false;
        this.loading = false;
        console.error(e);
      });
      this.loading = false;
      this.emitCalendarRefresh();
      setTimeout(() => {
        this.inactive = false;
      }, 1000 * 10);
      console.log(data);
    },
    emitCalendarRefresh() {
      this.$emit('refresh-events');
    },
  },
};
</script>
