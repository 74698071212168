<template>
  <div class="relative">
    <spinner-overlay color="transparent" :loading="loading" />
    <div
      v-if="nextAvailableSlot"
      class="flex items-center justify-between space-x-2"
      :class="{ 'opacity-25': loading }"
    >
      <div class="inline-flex items-center space-x-1 text-sm">
        <fv-icon class="text-gray-600" icon="clock" size="xs" />
        <span class="font-semibold text-gray-700">{{
          formatDistance(new Date(nextAvailableSlot.dateTime), new Date(), {
            addSuffix: false,
          })
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  onBeforeUnmount,
} from '@vue/composition-api';
import { useAvailability } from '@/composables/use-availability';
import { __DEV__ } from '@/constants';
import { formatDistance } from 'date-fns';

export default defineComponent({
  props: {
    country: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatDistance,
    };
  },
  setup(props) {
    const {
      result,
      fetchData,
      loadingState: loading,
      url,
    } = useAvailability(
      `api/appointmentdata/next/${props.country.id}`,
      'get',
      {}
    );
    const nextAvailableSlot = computed(() => result.value.next);
    const isDev = computed(() => __DEV__);

    onMounted(() => fetchData());

    const interval = setInterval(fetchData, 1000 * 30);

    onBeforeUnmount(() => {
      clearInterval(interval);
    });
    return { nextAvailableSlot, fetchData, loading, url, isDev };
  },
});
</script>
